export default [
  {
    header: 'User Interface',
  },
  {
    title: 'Cards',
    icon: 'CreditCardIcon',
    tag: 'new',
    tagVariant: 'light-success',
    children: [
      {
        title: 'Basic',
        route: 'card-basic',
      },
      {
        title: 'Advance',
        route: 'card-advance',
      },
      {
        title: 'Statistics',
        route: 'card-statistic',
      },
      {
        title: 'Analytics',
        route: 'card-analytic',
      },
      {
        title: 'Card Actions',
        route: 'card-action',
      },
    ],
  },
]
