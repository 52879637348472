<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="totalNotification"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ totalNotification }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="listNotification"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in listNotification"
        :key="notification.subtitle"
        :to="notification.to"
      >
        <b-card
          v-if="notification.number"
          class="mt-1 mb-1"
        >
          <span class="font-weight-bolder">
            <b-badge :variant="notification.type">
              {{ notification.number }}
            </b-badge>
            {{ notification.title }}
          </span>
          <br>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-card>
      </b-link>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BLink, BCard,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BLink,
    BCard,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      timer: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notifications: {
        newOrders: {
          number: 0,
          title: 'нових замовлень',
          subtitle: '👋 Обробіть замовлення. Чим швидше Ви реагуєте, тим більше буде радий клієнт!',
          type: 'success',
          to: { name: 'orders-index' },
        },
        newMessages: {
          number: 0,
          title: 'нових повідомлень',
          subtitle: '👋 Перегляньте запити від клієнтів у розділі "Повідомлення"',
          type: 'info',
          to: { name: 'messages-index' },
        },
        alarmMessages: {
          number: 0,
          title: 'повідомлень потребують сповіщення про наявність товару',
          subtitle: '👋 Надішліть клієнтам інформацію про товарии, які з\'явились у наявності',
          type: 'warning',
          to: { name: 'messages-index' },
        },
      },
    }
  },
  computed: {
    listNotification() {
      return Object.values(this.notifications)
    },
    totalNotification() {
      let total = 0
      Object.values(this.notifications).forEach(i => { total += i.number })
      return total
    },
  },
  created() {
    this.fetchEventsList()
    this.timer = setInterval(this.fetchEventsList, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    fetchEventsList() {
      this.$http.get('/api/profile/notifications').then(res => {
        this.notifications.newOrders.number = res.data.newOrders
        this.notifications.newMessages.number = res.data.newMessages
        this.notifications.alarmMessages.number = res.data.alarmMessages
      })
      // this.cancelAutoUpdate()
    },
    cancelAutoUpdate() {
      clearInterval(this.timer)
    },
  },
}
</script>

<style>

</style>
